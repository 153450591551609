<template>
  <div>
    <!--  -->
    <!-- <div
      class="imgqr"
      ref="bill"
      
      :style="imgqehead > 10 ? 'height:' + imgqehead + 'px;' : ''"
      v-for="(item, index) in imglist"
      :key="index"
    > -->
    <div class="imgqr-head" v-if="item.inviteLink.indexOf('type') == -1">
      <div>
        <img :src="item.avatar" crossorigin="anonymous" alt="" />
        <div>
          <p>{{ item.nickname }}</p>
          <p>我的邀请码：{{ item.code }}</p>
        </div>
      </div>
      <img
        src="https://ppyos.xijiuyou.com/imgs/invite/invite-card-tips.png"
        crossorigin="anonymous"
        alt=""
      />
    </div>
    <div class="qelogo" v-if="item.inviteLink.indexOf('type') == -1">
      <div class="qelogo-head">
        <div>
          <img
            src="https://ppyos.xijiuyou.com/imgs/invite/invite-up20.png"
            crossorigin="anonymous"
            alt=""
          />
          <p>
            同款游戏 <br />
            奖励多20%
          </p>
        </div>
        <div>
          <img
            src="https://ppyos.xijiuyou.com/imgs/invite/invite-1000card.png"
            crossorigin="anonymous"
            alt=""
          />
          <p>
            1000元收益卡<br />
            最高再奖20%
          </p>
        </div>
      </div>
      <div style="width: 100%">
        <div id="qrcode0"></div>
      </div>
      <img
        :src="packageUtilsl()"
        crossorigin="anonymous"
        alt=""
      />
    </div>
    <img
      v-if="item.inviteLink.indexOf('type') == -1"
      src="https://ppyos.xijiuyou.com/imgs/invite/invite-thres-bg.png"
      crossorigin="anonymous"
      class="imgqr-bottom"
      alt=""
    />
    <div class="imgqr-head2" v-if="item.inviteLink.indexOf('type') != -1">
      <div>
        <div>
          <img :src="item.avatar" crossorigin="anonymous" alt="" />
          <div>
            <p>{{ item.nickname }}</p>
            <p>我的邀请码：{{ item.code }}</p>
          </div>
        </div>
        <img
          :src="item.inviteLink.indexOf('type=1') != -1?'https://ppyos.xijiuyou.com/imgs/invite/act/play-cjpk-togger.png':'https://ppyos.xijiuyou.com/202201/free-draw-txt.jpg'"
          crossorigin="anonymous"
          alt=""
        />
      </div>
      <div class="imgqr-head2-bottom">
        <div id="qrcode1" v-if="item.inviteLink.indexOf('type=1') != -1"></div>
        <div id="qrcode2" v-else></div>
        <img
          crossorigin="anonymous"
          src="https://ppyos.xijiuyou.com/202201/ppyw-txt.jpg"
          alt=""
        />
      </div>
    </div>
    <img
      v-if="item.inviteLink.indexOf('type') != -1"
      :src="item.inviteLink.indexOf('type=1') != -1?'https://ppyos.xijiuyou.com/imgs/invite/cypk-inv-bg.png':'https://ppyos.xijiuyou.com/202201/rdskin-landing-bg.jpg'"
      crossorigin="anonymous"
      class="imgqr-bottom"
      alt=""
    />
    <!-- </div> -->
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import packageUtils from "../../utils/packageUtils"
// import qrcode from "vue_qrcodes";
export default {
  components: {
    // qrcode,
    // eslint-disable-next-line vue/no-unused-components
    QRCode,
  },
  props: {
    item: {
      type: Object,
    },
    imgqehead: {
      type: Number,
    },
  },
  mounted() {
    console.log(this.item.inviteLink)
    this.qrcode(this.item.inviteLink);
  },
  methods: {
    packageUtilsl(){
      return packageUtils.getCurrentPackage()!=='com.ppy.ppyw'?'https://ppyos.xijiuyou.com/coinVip/com/jiuyou/caibeiwan/invite-icon-text.png':'https://ppyos.xijiuyou.com/202201/ppy-icontxt.jpg'
    },
    qrcode(url) {
      if (url.indexOf("type") == -1) {
        document.getElementById("qrcode0").innerHTML = "";
        new QRCode("qrcode0", {
          width: 144, // 二维码宽度，单位像素
          height: 144, // 二维码高度，单位像素
          text: url, // 生成二维码的链接
        });
      } else {
        if(url.indexOf("type=1") == -1){
          document.getElementById("qrcode2").innerHTML = "";
          new QRCode("qrcode2", {
            width: 64, // 二维码宽度，单位像素
            height: 64, // 二维码高度，单位像素
            text: url, // 生成二维码的链接
          });
        }else{
          document.getElementById("qrcode1").innerHTML = "";
          new QRCode("qrcode1", {
            width: 64, // 二维码宽度，单位像素
            height: 64, // 二维码高度，单位像素
            text: url, // 生成二维码的链接
          });
        }
        
      }
    },
  },
};
</script>
<style scoped>
.imgqr {
  width: 100vw;
  height: 100vh;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
}
.imgqr-bottom {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.imgqr-head {
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  position: relative;
  z-index: 1;
  padding: 12px;
}
.imgqr-head div:nth-child(1) {
  display: flex;
}
.imgqr-head div:nth-child(1) img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}
.imgqr-head div:nth-child(1) p:nth-child(1) {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.imgqr-head div:nth-child(1) p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-top: 4px;
}
.imgqr-head img:nth-child(2) {
  width: 100%;
  margin-top: 9px;
}
.qelogo {
  position: relative;
  z-index: 1;
  width: calc(100% - 116px);
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 12px;
}
.qelogo-head {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 14px;
}
.qelogo-head img {
  width: 76px;
  height: 71px;
}
.qelogo-head p {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff5675;
}
.qelogo img:nth-child(3) {
  width: 118px;
  margin-top: 12px;
}

.imgqr-head2 {
  width: calc(100% - 24px);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  position: absolute;
  bottom: 12px;
  z-index: 1;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}
.imgqr-head2 div div:nth-child(1) {
  display: flex;
}
.imgqr-head2 div div:nth-child(1) img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}
.imgqr-head2 div div:nth-child(1) p:nth-child(1) {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.imgqr-head2 div div:nth-child(1) p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-top: 4px;
  white-space: nowrap;
}
.imgqr-head2 div img:nth-child(2) {
  width: 89%;
  margin-top: 9px;
}
.imgqr-head2-bottom {
  font-size: 16px;
  font-family: HelloFont-WenYiHei, HelloFont;
  font-weight: normal;
  color: #444444;
}
.imgqr-head2-bottom img {
  width: 64px;
}

.banner {
  width: calc(100% - 40px);
  height: 110%;
}
.popupcontent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 10px;
  position: relative;
}
.bottombtn {
  width: calc(100% - 70px) !important;
  height: 48px;
  background: #ffffff;
  border-radius: 36px;
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.bottombtn img {
  width: 17px;
  margin-right: 15px;
}
.bottombtns {
  width: 100%;
  height: 110px;
  position: absolute;
  bottom: 0;
  background: #ffffff;
}
.bottombtns {
  display: flex;
  justify-content: space-around;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.bottombtns img {
  width: 48px;
  height: 48px;
  margin-top: 20px;
}
.bottombtns div {
  width: 100%;
  height: 100%;
}
.bottombtns p {
  margin-top: 6px;
}
</style>
<style scoped>
.imgqr-head2-bottom canvas {
  display: none;
}
.van-swipe__track {
  align-items: center;
}
.van-swipe-item {
  padding-left: 30px;
  box-sizing: border-box;
}
.share {
  margin-top: 100px;
}
.share img {
  width: calc(100% - 88px);
}
.share img:nth-child(3) {
  width: 26px;
  height: 26px;
  margin-top: 24px;
}
.share-center {
  width: calc(100% - 70px);
  height: 198px;
  background: linear-gradient(180deg, #fff5d8 0%, #ffffff 100%);
  box-shadow: 0px 2px 0px 0px #ffffff;
  border-radius: 15px;
  margin: -5px auto 0;
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
  padding-top: 23px;
}
.share-center-le {
  padding: 0 5px;
  box-sizing: border-box;
  width: calc(50% - 7px);
  height: 160px;
  background: #ffb7c7;
  box-shadow: 0px 3px 3px 0px rgba(255, 255, 255, 0.5) inset,
    0px -3px 3px 0px rgba(255, 98, 137, 0.6) inset;
  border-radius: 15px;
  padding-top: 10px;
}
.share-center-le p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #000000;
}
.share-center-le div:nth-child(2) {
  width: 100%;
  height: 56px;
  background: #ff8299;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 10px;
  padding: 0 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  box-sizing: border-box;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}
.share-center-le p:nth-child(3) {
  margin: 0 auto;
  margin-top: 10px;
  width: calc(100% - 5px);
  height: 40px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  border-radius: 23px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-center-ri {
  padding: 0 5px;
  box-sizing: border-box;
  width: calc(50% - 7px);
  height: 160px;
  background: #ffd380;
  box-shadow: 0px 3px 3px 0px rgba(255, 255, 255, 0.5) inset,
    0px -3px 3px 0px rgba(255, 131, 0, 0.7) inset;
  border-radius: 15px;
  padding-top: 10px;
}
.share-center-ri p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #000000;
}
.share-center-ri div:nth-child(2) {
  width: 100%;
  height: 56px;
  background: #ffb146;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 18px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-center-ri p:nth-child(3) {
  margin: 0 auto;
  margin-top: 10px;
  width: calc(100% - 5px);
  height: 40px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  border-radius: 23px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}
#qrcode0{
    display: flex;
    justify-content: center;
}
</style>