<template>
  <div class="swiper-container" id="swiper1">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(item, index) in imgqr"
        :key="index"
      >
        <img :src="item.imgblob" class="bookImg banner" />
        <div class="bottombtn" @click="qeimg(item.img)">
            <img
            src="https://ppyos.xijiuyou.com/imgs/invite/saveicon.png"
            alt=""
            />
            <span>保存二维码图片</span>
        </div>
      </div>
    </div>
    <div  class="swiper-button-prev"></div>
    <div  class="swiper-button-next"></div> 
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
import { Toast } from "vant";
export default {
  name: "Swiper", // 此处不能用Swiper作为name，否则报错
  data() {
    return {
      swiperOptions: {
        loop:true, // 循环
      },
    };
  },
  props:{
      imgqr:{
          type:Array
      }
  },
  mounted(){
      new Swiper("#swiper1", {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      observer: true,
      effect: "coverflow",
      slidesPerView: "auto",
      centeredSlides: true,
      coverflowEffect: {
        rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
        stretch: -5, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
        depth: 20, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
        modifier: 15,
        slideShadows: false, //开启slide阴影。默认 true。
      },
    });
  },
  methods:{
    qeimg(res){
      try{
        window.android.saveImageBy64(res);
        window.android.shareToWX(
          0,
          2,
          "送你一个虎年定制微信红包封面，有10款随你挑哦，限时限量发放",
          "送你一个虎年定制微信红包封面，有10款随你挑哦，限时限量发放",
          res,
          1,
          ""
        );
      }catch(e){
        Toast('您当前app版本过低请更新app');
      }
      this.$emit('closeHandle');
    },
  }
};
</script>
<style scoped>
#swiper1 {
  width: 100%;
}
.swiper-slide {
  width: 60vw !important;
  font-size: 14px;
  text-align: center;
  line-height: 80px;
  border-radius: 8px;
  position: relative;
}
.bookImg {
  width: 55vw!important;
}
.bookName {
  font-size: 14px;
  width: 100%;
  display: none;
  margin-top: -55px;
  text-overflow: ellipsis;
   white-space: nowrap;
}
.swiper-slide-active .bookName {
  display: block;
}
.bottombtn {
  width: calc(100% - 50px) !important;
  height: 48px;
  background: #ffffff;
  border-radius: 36px;
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.bottombtn img {
  width: 17px;
  margin-right: 15px;
}
.swiper-button-prev{
  height: 100vh;
  top: 0;
  width: 50px;
  left: 0;
  background: rgba(0,0,0,0);
}
.swiper-button-next{
  height: 100vh;
  top: 0;
  width: 50px;
  right: 0;
  background: rgba(0,0,0,0);
}
</style>
