<template>
  <div class="content">
    <div class="horselamp"  :style="`width:${redswidth*2.2}px;`">
      <div  :style="`margin-left:${-gotime}px;`"  >
        <div v-for="(item,index) in horselamp" :key="index" ref="reds">
          <img :src="item.avatar?item.avatar:'https://ppyos.xijiuyou.com/imgs/invite/default_avatar.png'" alt="">
          <span>{{item.nickname}}邀请了{{item.inviteCount}}名好友，获得{{item.coin}}元</span>
        </div>
      </div>
      <div>
        <div v-for="(item,index) in horselamp" :key="index">
          <img :src="item.avatar?item.avatar:'https://ppyos.xijiuyou.com/imgs/invite/default_avatar.png'" alt="">
          <span>{{item.nickname}}邀请了{{item.inviteCount}}名好友，获得{{item.coin}}元</span>
        </div>
      </div>
    </div>
    <div class="guiz" @click="rules = true">规则</div>
    <div class="invitation">
      <div class="reward">
        <template v-if="normalTasks.length > 0">
          <div class="reward-top" v-for="item in normalTasks" :key="item.id">
            <div>
              <p>邀请的好友行为</p>
              <p>我的奖励</p>
            </div>
            <div>
              <p>{{ item.title }}</p>
              <p v-html="item.awardTips"></p>
            </div>
          </div>
        </template>
        <div class="reward-list-explain">*福利奖任务不包含在内</div>
        <div
          class="reward-list"
          v-for="(item, index) in taskslist"
          :key="index"
        >
          <div>即日起至{{ time(item.expiredTime) }}</div>
          <div v-html="item.tips"></div>
          <div @click="takeTaskAward(item)" :style='item.taskQueryReturn.status === 0
                ? "background: linear-gradient(270deg, rgba(97, 167, 255, 0.3) 0%, rgba(114, 206, 255, 0.3) 100%);color:rgba(39, 135, 255, 1);"
                : item.taskQueryReturn.status === 1
                ? `background: linear-gradient(270deg, #61A7FF 0%, #72CEFF 100%);color:#ffffff;`
                : item.taskQueryReturn.status === 2 || item.taskQueryReturn.status === 4
                ? `background: rgba(212, 212, 212, 1);color:#ffffff;`
                : item.taskQueryReturn.status === 3
                ? `background: linear-gradient(314deg, rgba(255, 86, 117, 0.2) 0%, rgba(255, 136, 109, 0.2) 100%);color:rgba(255, 86, 117, 1);`
                : "background: linear-gradient(270deg, #61A7FF 0%, #72CEFF 100%) #D4D4D4;color:#ffffff;"'>
            {{
              item.taskQueryReturn.status === 0
                ? "审核待发放"
                : item.taskQueryReturn.status === 1
                ? `领取奖励${item.taskQueryReturn.coin}元`
                : item.taskQueryReturn.status === 2
                ? `今日已领 明日再来`
                : item.taskQueryReturn.status === 3
                ? `审核不通过 请联系客服`
                : item.taskQueryReturn.status === 4
                ? `差${item.taskQueryReturn.needPerson}人可领奖励`
                : "活动过期或还没开始"
            }}
          </div>
        </div>
        <img
          @click="erqrcode"
          class="btn"
          src="https://ppyos.xijiuyou.com/202208/invite-btn-img.png"
          alt=""
        />
      </div>
      <div class="invitegl" v-for="(item,index) in normalTasks" :key="index">
        <img
          src="https://ppyos.xijiuyou.com/202208/invite-gl-img-05cb.png"
          alt=""
        />
        <div>
          <img src="https://ppyos.xijiuyou.com/202208/red-tips-bg.jpg" alt="">
          <span>当前{{item.taskQueryReturn.maxAwardPerFriend}}次/位</span>
        </div>
      </div>

      <div class="Invitescallops">
        <div>
          <p>邀请获得彩贝</p>
          <p>1元=10万彩贝</p>
          <p>{{ currency.formattedCoin }}</p>
          <div>
            <p>
              我的邀请： <span>{{ currency.totalInvite }}</span
              >人
            </p>
            <p>
              今日有效邀请：<span>{{ currency.effectiveInvite }}</span
              >人
            </p>
          </div>
        </div>
        <div @click="cashing">立即兑换</div>
        <div @click="detailed">邀请明细</div>
      </div>
      <img
        @click="golinks"
        class="bannerimg"
        :src="bannercoter?bannercoter.bannerImg:''"
        alt=""
      />
    </div>
    <div
      class="imgqr"
      ref="bill"
      :style="imgqehead > 10 ? 'height:' + imgqehead + 'px;' : ''"
      v-for="(item, index) in imglist"
      :key="index"
    >
      <img-qr :item="item" :imgqehead="imgqehead"></img-qr>
    </div>
    <xwpopup
      :isshowpopup="isshowpopup"
      @close="closeHandle()"
      :hiddenClose="true"
    >
      <div class="popupcontent" @click.self="closeHandle">
        <swipers
          v-if="qema != 0"
          :imgqr="imgqr"
          @closeHandle="closeHandle"
        ></swipers>
        <div class="share" v-else>
          <img
            src="https://ppyos.xijiuyou.com/imgs/invite/sharetofri.png"
            alt=""
          />
          <div class="share-center">
            <div
              class="share-center-le tag-read"
              :data-clipboard-text="qrcodeUrl"
              @click="copy"
            >
              <p>专属链接</p>
              <div>{{ qrcodeUrl }}</div>
              <p>复制链接</p>
            </div>
            <div
              class="share-center-ri tag-reads"
              :data-clipboard-text="code"
              @click="copys"
            >
              <p>我的邀请码</p>
              <div>{{ code }}</div>
              <p>复制邀请码</p>
            </div>
          </div>
          <img
            @click="closeHandle"
            src="https://ppyos.xijiuyou.com/210916/close-btn.jpg"
            alt=""
          />
        </div>
        <div class="bottombtns">
          <div
            @click="qema = 1"
            :style="qema == 0 ? 'background:rgba(0,0,0,0.04);' : ''"
          >
            <img
              src="https://ppyos.xijiuyou.com/imgs/invite/act/task-icon-2.png"
              alt=""
            />
            <p>二维码邀请</p>
          </div>
          <div
            @click="qema = 0"
            :style="qema == 1 ? 'background:rgba(0,0,0,0.04);' : ''"
          >
            <img
              src="https://ppyos.xijiuyou.com/imgs/invite/act/task-icon.png"
              alt=""
            />
            <p>邀请码邀请</p>
          </div>
        </div>
      </div>
    </xwpopup>


    <xwpopup :isshowpopup="rules" @close="closeguiz()" :hiddenClose="false">
      <div class="rulelist">
        <img class="rulelisthead" src="https://ppyos.xijiuyou.com/202208/invite-task-rule.png" alt="">
        <div class="rulep">
          <p>
            <span>1.</span>
            <span
              >好友必须是本平台的新用户才算邀请成功(手机和微信帐号均未注册登录过本平台) </span
            >
          </p>
          <p>
            <span>2.</span>
            <span
              >
所有邀请奖励需要邀请的好友成功完成赚钱频道的高额赚任务并领取奖励后，即可正常自动发放（福利奖任务不算在内）</span
            >
          </p>
          <p>
            <span>3.</span>
            <span
              >奖励以等值的彩贝自动发放到您的彩贝账户（1元=10万彩贝），彩贝可以兑换商品</span
            >
          </p>
          <p>
            <span>4.</span>
            <span
              >为保证广大用户及平台的收益不受影响，平台会对存在违规邀请、采用作弊手段等的用户进行冻结处理，并扣除所得奖励</span
            >
          </p>
          <p>
            <span>5.</span
            ><span>本次邀请活动，必须是在活动开启后邀请的用户，达到活动的奖励条件才能自动发奖；活动开启前邀请的用户，可能无法正常完成部分奖励任务</span>
          </p>
          <p><span>6.</span><span>活动有效期：自即日起生效，活动规则可能随版本更新发生变更，有疑问可以咨询客服</span></p>
          <p><span>7.</span><span>邀请限时活动，请不要忘记在达到目标后的次日领取奖励</span></p>
          <p><span>8.</span><span>本活动最终解释权归本平台所有</span></p>
          <div class="skill">邀请技巧</div>
          <p><span>1.</span><span>邀请身边家人、朋友、同学成功率更高</span></p>
          <p><span>2.</span><span>通过其他任务平台邀请，或分享到多个的微信群，成功邀请的几率提升200%</span></p>
          <p><span>3.</span><span>可以告诉您的好友，这里有各种简单好玩的游戏，玩的越多赚的越多，立即可以提现</span></p>
        </div>
      </div>
    </xwpopup>
  </div>
</template>
<script>
import home from "../../api/home";
import game from "../../api/game";
import html2canvas from "html2canvas";
import active from "../../api/active";
import Clipboard from "clipboard";
import { Toast } from "vant";
import xwpopup from "../../components/Popupbox.vue";
import swipers from "../../components/game/swiper.vue";
import imgQr from "./img.vue";
import dayjs from "dayjs";
export default {
  components: {
    xwpopup,
    swipers,
    imgQr,
  },
  data() {
    return {
      isshowpopup: false,
      qrcodeUrl: "",
      iconurl: "",
      code: "",
      nickname: "",
      taskslist: [],
      currency: {},
      imglist: [],
      qema: 1,
      imgqr: [],
      current: 0,
      imgqehead: 0,
      yaoqing: true,
      endTime: "",
      normalTasks: [],
      bannercoter:{},
      horselamp:[
      ],
      times:0,
      gotime:0,
      rules:false,
      redswidth:0,
    };
  },
  mounted() {
    try {
      this.imgqehead =
        (window.android.getScreenHeight() / window.android.getScreenWidth()) *
        document.body.clientWidth;
    } catch (err) {
      this.imgqehead = 0;
    }
    home.myInviteLink().then((res) => {
      this.imglist = [
        {
          avatar: res.data.avatar,
          code: res.data.code,
          inviteLink: res.data.inviteLink + "&type=1",
          nickname: res.data.nickname,
          userId: res.data.userId,
        },
        // {
        //   avatar: res.data.avatar,
        //   code: res.data.code,
        //   inviteLink: res.data.inviteLink + "&type=2",
        //   nickname: res.data.nickname,
        //   userId: res.data.userId,
        // },
        res.data,
      ];
      this.qrcodeUrl = res.data.inviteLink;
      this.iconurl = res.data.avatar;
      this.code = res.data.code;
      this.nickname = res.data.nickname;
      setTimeout(() => {
        this.arrimglist();
      }, 0);
    });
    this.inviteAggregation();
    this.tasks();
    this.bannerfind();
    this.marquee();
  },
  destroyed(){
    const that = this;
    clearInterval(that.times);
  },
  methods: {
    gohorse(){
      const that = this;
      that.$nextTick(()=>{
        if(this.horselamp.length<1){
          return false;
        }
        that.$refs.reds.map(res=>{
          console.log(res.clientWidth)
          that.redswidth = that.redswidth+res.clientWidth;
        })
        that.redswidth = that.redswidth+(that.horselamp.length*80)+40
        that.times = setInterval(()=>{
          if(that.gotime>= that.redswidth){
            that.gotime = 0
          }else{
            that.gotime++
          }
        },10)
      })
    },
    marquee(){
      game.marquee().then(res=>{
        this.horselamp = res.data;
        this.gohorse();
      })
    },
    golinks(){
      window.location.href = this.bannercoter.link;
    },
    bannerfind(){
      game.bannerfind(20).then(res=>{
        this.bannercoter = res.data[0]
      })
    },
    takeTaskAward(item){
      if(item.taskQueryReturn.status ===1){
        home.takeTaskAward(item.id).then(()=>{
          Toast("奖励领取已提交");
          const time = setTimeout(()=>{
            this.tasks();
            clearTimeout(time);
          },500)
        })
      }
    },
    time(item) {
      return dayjs(item).format("MM月DD日");
    },
    closeHandle() {
      this.isshowpopup = false;
    },
    onChange(index) {
      this.current = index;
    },
    erqrcode() {
      if (this.yaoqing == false) return false;
      this.yaoqing = false;
      try {
        active.inviteActionLog();
      } catch (err) {
        console.log(err);
      }
      this.isshowpopup = true;
      this.rulesbool = false;
      if (this.imgqr.length > 0) {
        this.yaoqing = true;
        return false;
      }
    },
    async arrimglist() {
      const that = this;
      const bill0 = await html2canvas(this.$refs.bill[0], { useCORS: true });
      bill0.toBlob(
        function (blob) {
          that.imgqr.push({
            imgblob: URL.createObjectURL(blob),
            img: bill0.toDataURL("image/png"),
          });
        },
        "image/jpeg",
        0.95
      );
      const bill1 = await html2canvas(this.$refs.bill[1], { useCORS: true });
      bill1.toBlob(
        function (blob) {
          that.imgqr.push({
            imgblob: URL.createObjectURL(blob),
            img: bill1.toDataURL("image/png"),
          });
        },
        "image/jpeg",
        0.95
      );
      // const bill2 = await html2canvas(this.$refs.bill[2], { useCORS: true });
      // bill2.toBlob(
      //   function (blob) {
      //     that.imgqr.push({
      //       imgblob: URL.createObjectURL(blob),
      //       img: bill1.toDataURL("image/png"),
      //     });
      //   },
      //   "image/jpeg",
      //   0.95
      // );
    },
    inviteAggregation() {
      active.inviteAggregation().then((res) => {
        this.currency = res.data;
      });
    },
    detailed() {
      // this.$router.push({
      //   name: "detailed",
      // });
      try {
        window.android.createNewWeb(
          window.location.href.split("loading")[0] + "detailed"
        );
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    cashing() {
      // this.$router.push({
      //   name: "caibeiexchange",
      // });
      try {
        window.android.createNewWeb(
          window.location.href.split("loading")[0] + "caibeiexchange"
        );
      } catch (e) {
        Toast("您当前app版本过低请更新app");
      }
    },
    closeguiz(){
      this.rules = !this.rules
    },
    tasks() {
      active.tasks().then((res) => {
        this.taskslist = res.data.timeLimitTasks;
        this.normalTasks = res.data.normalTasks;
      });
    },
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", () => {
        Toast("复制成功");
        this.closeHandle();
      });
    },
    copys() {
      var clipboard = new Clipboard(".tag-reads");
      clipboard.on("success", () => {
        Toast("复制成功");
        this.closeHandle();
      });
    },
  },
};
</script>
<style scoped>
.content {
  position: relative;
  overflow: hidden;
}
.horselamp{
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1000;
  padding-top: 9px;
  display: flex;
}
.horselamp div div img{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
  float: left;
  margin: 4px;
}
.horselamp div div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: inline-block;
  height: 32px;
  background: rgba(43,114,229,0.6);
  border-radius: 16px;
  box-sizing: border-box;
  margin-right:80px;
}
.horselamp div{
  display: block;
}
.horselamp div div span{
  line-height: 32px;
  height: 32px;
  overflow: hidden;
  width: calc(100% - 32px);
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
  display: flex;
  align-items: center;
  padding-right: 4px;
}
.invitation {
  position: relative;
  z-index: 100;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  background: url("http://ppyos.xijiuyou.com/202208/invite-bg-banner.png")
    no-repeat;
  background-size: 100%;
  background-color: #f5e2ff;
  padding-bottom: 20px;
  padding-top: 174px;
}
.reward {
  width: calc(100% - 26px);
  background: url("https://ppyos.xijiuyou.com/202208/invite-list-bg.png?t=1")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  padding-bottom: 3px;
  margin: 0 auto 10px;
  box-sizing: border-box;
}
.reward-top {
  width: calc(100% - 26px);
  background: #ffffff;
  margin: 0 auto;
  border-radius: 10px;
}
.reward-top div {
  display: flex;
}
.reward-top div:nth-child(1) {
  border-bottom: 1px solid rgba(165, 196, 255, 0.5);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
}
.reward-top div:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.reward-top div p {
  padding: 11px 0;
  text-align: left;
}
.reward-top div p span {
  color: rgba(255, 86, 117, 1);
}
.reward-top div p:nth-child(1) {
  width: 60%;
  border-right: 1px solid rgba(165, 196, 255, 0.5);
  padding-left: 18px;
}
.reward-top div p:nth-child(2) {
  width: 40%;
  padding-left: 18px;
}
.reward-list {
  width: calc(100% - 26px);
  background: #ffffff;
  margin: 16px auto 0;
  border-radius: 15px;
  padding-bottom: 16px;
}
.reward-list-explain{
  width: calc(100% - 28px);
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2673DB;
  text-align: left;
  padding-left: 18px;
  margin-top: 8px;
}
.reward-list div:nth-child(1) {
  width: 100%;
  height: 39px;
  text-align: center;
  line-height: 35px;
  background: url("https://ppyos.xijiuyou.com/202208/award-xs-banner.png")
    no-repeat;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b04b5e;
  padding-left: 20px;
}
.reward-list div:nth-child(2) {
  padding: 0 30px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  text-align: left;
  margin-bottom: 14px;
}
.reward-list div:nth-child(2) span {
  color: rgba(255, 86, 117, 1);
}
.reward-list div:nth-child(3) {
  width: 161px;
  height: 40px;
  background: linear-gradient(270deg, #61a7ff 0%, #72ceff 100%);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 0 auto;
}
.btn {
  width: calc(100% - 90px);
  margin-top: 13px;
}
.invitegl {
  width: calc(100% - 28px);
  margin: 0 auto;
  position: relative;
}
.invitegl img{
  width: 100%;
}
.invitegl div{
  position: absolute;
  right: 24px;
  top: 12px;
}
.invitegl div img {
  width: 75px;
  position: absolute;
  left: 0;
  top: 0;
}
.invitegl div span{
  position: relative;
  z-index: 1;
  font-size: 10px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  display: block;
  width: 75px;
  height: 22px;
  line-height: 25px;
}
.Invitescallops {
  width: calc(100% - 24px);
  background: url("https://ppyos.xijiuyou.com/202208/prue-bg-img.png")
    no-repeat;
  background-size: 100% 100%;
  margin: 7px auto 10px;
  padding: 13px 12px 20px;
  box-sizing: border-box;
}
.Invitescallops div:nth-child(1) {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding-top: 12px;
  padding-bottom: 18px;
}
.Invitescallops div:nth-child(1) p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.Invitescallops div:nth-child(1) p:nth-child(2) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(68, 68, 68, 0.5);
}
.Invitescallops div:nth-child(1) p:nth-child(3) {
  font-size: 30px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #ff5675;
  margin: 8px 0 12px;
}
.Invitescallops div:nth-child(1) div:nth-child(4) {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.Invitescallops div:nth-child(1) div:nth-child(4) span {
  font-size: 16px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #444444;
}

.Invitescallops div:nth-child(2) {
  width: 249px;
  height: 48px;
  background: linear-gradient(270deg, #61a7ff 0%, #72ceff 100%);
  border-radius: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 18px auto 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Invitescallops div:nth-child(3) {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}

.activity {
  width: calc(100% - 24px);
  height: 216px;
  background: url("https://ppyos.xijiuyou.com/imgs/invite/act/panl.png")
    no-repeat;
  background-size: 100% 100%;
  margin: 17px auto 10px;
  padding: 9px 14px 0;
  box-sizing: border-box;
}
.title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  padding-left: 20px;
}
.currency {
  display: flex;
  justify-content: space-between;
}
.currency-content {
  width: 50%;
}
.currency-back {
  width: 95%;
  height: 94px;
  background: rgba(255, 175, 52, 0.09);
  border-radius: 20px 4px 20px 4px;
  margin-left: 3px;
  margin-top: 35px;
  position: relative;
  padding-top: 8px;
}
.currency-back img:nth-child(1) {
  position: absolute;
  width: 16px;
  left: 0;
  top: -6px;
}
.currency-back div {
  width: 46px;
  height: 46px;
  background: url("https://ppyos.xijiuyou.com/imgs/invite/act/coin-icon.png")
    no-repeat;
  background-size: 100%;
  margin: 0 auto;
  position: relative;
}
.currency-back div p {
  width: 32px;
  height: 21px;
  background: linear-gradient(
    270deg,
    rgba(255, 112, 112, 0.8) 0%,
    rgba(255, 116, 89, 0.8) 100%
  );
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  border-radius: 11px;
  font-size: 14px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  right: -13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.currency-yao {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a05632;
  margin-top: 7px;
}
.currency-yao span {
  font-weight: bold;
}
.currency-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  width: 90%;
  margin-top: 8px;
}
.current {
  width: calc(100% - 24px);
  height: 260px;
  background: url("https://ppyos.xijiuyou.com/imgs/invite/act/panl-bg.png")
    no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  padding-top: 12px;
}
.current p:nth-child(2) {
  width: calc(100% - 130px);
  height: 48px;
  background: linear-gradient(270deg, #ff914d 0%, #ffc166 100%);
  border-radius: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
}
.current p:nth-child(3) {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  text-align: center;
}
.current-content {
  width: calc(100% - 24px);
  height: 126px;
  background: rgba(255, 175, 52, 0.09);
  margin: 0 auto;
  border-radius: 6px;
  padding-top: 12px;
}
.current-content div:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a05632;
}
.current-content div:nth-child(2) {
  font-size: 30px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #ff7436;
}
.current-content div:nth-child(2) img {
  width: 20px;
  height: 20px;
  margin-left: -5px;
}
.current-content div div:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a05632;
}
.current-content div:nth-child(3) {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 15px;
}
.current-content div:nth-child(3) span {
  font-size: 16px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #a05632;
}
.imgqr {
  width: 100vw;
  height: 100vh;
  padding: 12px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
}
.imgqr-bottom {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.imgqr-head {
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  position: relative;
  z-index: 1;
  padding: 12px;
}
.imgqr-head div:nth-child(1) {
  display: flex;
}
.imgqr-head div:nth-child(1) img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}
.imgqr-head div:nth-child(1) p:nth-child(1) {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.imgqr-head div:nth-child(1) p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-top: 4px;
}
.imgqr-head img:nth-child(2) {
  width: 100%;
  margin-top: 9px;
}
.qelogo {
  position: relative;
  z-index: 1;
  width: calc(100% - 116px);
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 12px;
}
.qelogo-head {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 14px;
}
.qelogo-head img {
  width: 76px;
  height: 71px;
}
.qelogo-head p {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff5675;
}
.qelogo img:nth-child(3) {
  width: 118px;
  margin-top: 12px;
}

.imgqr-head2 {
  width: calc(100% - 24px);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  position: absolute;
  bottom: 12px;
  z-index: 1;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}
.imgqr-head2 div div:nth-child(1) {
  display: flex;
}
.imgqr-head2 div div:nth-child(1) img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}
.imgqr-head2 div div:nth-child(1) p:nth-child(1) {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
}
.imgqr-head2 div div:nth-child(1) p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-top: 4px;
  white-space: nowrap;
}
.imgqr-head2 div img:nth-child(2) {
  width: 89%;
  margin-top: 9px;
}
.imgqr-head2-bottom {
  font-size: 16px;
  font-family: HelloFont-WenYiHei, HelloFont;
  font-weight: normal;
  color: #444444;
}
.imgqr-head2-bottom img {
  width: 64px;
}

.banner {
  width: calc(100% - 40px);
  height: 110%;
}
.popupcontent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 10px;
  position: relative;
}
.bottombtn {
  width: calc(100% - 70px) !important;
  height: 48px;
  background: #ffffff;
  border-radius: 36px;
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
}
.bottombtn img {
  width: 17px;
  margin-right: 15px;
}
.bottombtns {
  width: 100%;
  height: 110px;
  position: absolute;
  bottom: 0;
  background: #ffffff;
}
.bottombtns {
  display: flex;
  justify-content: space-around;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.bottombtns img {
  width: 48px;
  height: 48px;
  margin-top: 20px;
}
.bottombtns div {
  width: 100%;
  height: 100%;
}
.bottombtns p {
  margin-top: 6px;
}
</style>
<style scoped>
.imgqr-head2-bottom canvas {
  display: none;
}
.van-swipe__track {
  align-items: center;
}
.van-swipe-item {
  padding-left: 30px;
  box-sizing: border-box;
}
.share {
  margin-top: 100px;
}
.share img {
  width: calc(100% - 88px);
}
.share img:nth-child(3) {
  width: 26px;
  height: 26px;
  margin-top: 24px;
}
.share-center {
  width: calc(100% - 70px);
  height: 198px;
  background: linear-gradient(180deg, #fff5d8 0%, #ffffff 100%);
  box-shadow: 0px 2px 0px 0px #ffffff;
  border-radius: 15px;
  margin: -5px auto 0;
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
  padding-top: 23px;
}
.share-center-le {
  padding: 0 5px;
  box-sizing: border-box;
  width: calc(50% - 7px);
  height: 160px;
  background: #ffb7c7;
  box-shadow: 0px 3px 3px 0px rgba(255, 255, 255, 0.5) inset,
    0px -3px 3px 0px rgba(255, 98, 137, 0.6) inset;
  border-radius: 15px;
  padding-top: 10px;
}
.share-center-le p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #000000;
}
.share-center-le div:nth-child(2) {
  width: 100%;
  height: 56px;
  background: #ff8299;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 10px;
  padding: 0 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  box-sizing: border-box;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}
.share-center-le p:nth-child(3) {
  margin: 0 auto;
  margin-top: 10px;
  width: calc(100% - 5px);
  height: 40px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  border-radius: 23px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-center-ri {
  padding: 0 5px;
  box-sizing: border-box;
  width: calc(50% - 7px);
  height: 160px;
  background: #ffd380;
  box-shadow: 0px 3px 3px 0px rgba(255, 255, 255, 0.5) inset,
    0px -3px 3px 0px rgba(255, 131, 0, 0.7) inset;
  border-radius: 15px;
  padding-top: 10px;
}
.share-center-ri p:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #000000;
}
.share-center-ri div:nth-child(2) {
  width: 100%;
  height: 56px;
  background: #ffb146;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 18px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-center-ri p:nth-child(3) {
  margin: 0 auto;
  margin-top: 10px;
  width: calc(100% - 5px);
  height: 40px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  border-radius: 23px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bannerimg {
  width: calc(100% - 24px);
}
.guiz{
  width: 24px;
  height: 45px;
  position: fixed;
  right: 0;
  top: 94px;
  z-index: 101;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  background: #FFFFFF;
  border-radius: 8px 0px 0px 8px;
  padding: 4px 5px;
  text-align: center;
}



.rulelist {
  width: calc(100vw - 70px);
  margin: 50px auto 0;
  background: #ffffff;
  border-radius: 15px;
  padding: 2px;
}

.rulelisthead {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: linear-gradient(270deg, #ffe5bb 0%, #fff9ed 100%);
  border-radius: 15px 15px 0px 0px;
}

.rulep {
  padding: 16px 26px;
  height: 400px;
  overflow-y: auto;
}
.rulep p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  text-align: left;
  display: flex;
  line-height: 25px;
}
.rulep p span:nth-child(1) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  display: inline-block;
}

.rulep p span:nth-child(2) {
  display: inline-block;
}
.skill{
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  text-align: left;
  margin-top: 20px;
  margin-left: 5px;
}
</style>
